<template lang="pug">
  .index-c-w
    .filter-box
      .filter-citem
        span.label-span1 市：
        Box3(w="152")
          el-select(class="my-select" v-model="city" :disabled="cityFlag" placeholder="请选择")
            el-option(v-for="item in cityList" :key="item.cityCode" :label="item.cityName" :value="item.cityCode")
      .filter-citem
        span.label-span2 区县：
        Box3(w="152")
          el-select(class="my-select" v-model="district" :disabled="districtFlag" placeholder="请选择")
            el-option(v-for="item in districtList" :key="item.districtCode" :label="item.districtName" :value="item.districtCode")
      .filter-citem
        span.label-span3 出险人姓名：
        Box3(w="152")
          <el-input class="my-input" v-model="name" placeholder="请输入"></el-input>
      .filter-citem
        .cur-btn(@click="onSearch(1,years)") 查 询
      .filter-citem(v-if="userInfo.readyOnly == 1")
        ImportFile(:action="action" @onImportSuccess="onImportSuccess")
    .table-box
      MyTable1(
        :tableData="claimList"
        :columns="columns"
        tH="65vh"
        :paginationShow="true"
        :total="pagation.total"
        :currentPage="currentPage"
        @onCurrentPage="onCurrentPage"
      )
        <template v-slot:action="{row}">
          .cur-btn(@click="onDetail(row)") 报案详情
        </template> 
</template>

<script>
import Box3 from "../../components/Box3.vue";
import MyTable1 from "../../components/MyTable1.vue";
import ImportFile from "../../components/ImportFile.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      city: "", //市
      district: "", //区县
      cityFlag: false,
      districtFlag: false,
      name: "", //出险人name
      currentPage: 1,
      //table 表头
      columns: [
        { label: "市", prop: "cityName" },
        { label: "区县", prop: "districtName" },
        { label: "出险人姓名", prop: "dangerPeopleName" },
        { label: "出险时间", prop: "dangerTime", minWidth: 140 },
        {
          label: "出险原因",
          className: "accident-reason",
          prop: "accidentReasonStrs",
          minWidth: 200,
        },
        { label: "估损/结案金额", prop: "amount", minWidth: 100 },
        { label: "案件状态", prop: "caseStatusName" },
        { label: "操作", slot: "action" },
      ],
      //table 数据
      tableData: [],
    };
  },
  components: {
    Box3,
    MyTable1,
    ImportFile,
  },
  watch: {
    city(newValue) {
      //监听市下拉选择 如果选择全部调用 api 无需传参，否则反正
      if (newValue === "all") {
        this.getDistrictListAction();
        this.district = '';
      } else {
        this.getDistrictListAction({ cityCode: newValue });
      }
    },
    years(newVal) {
      this.currentPage = 1;
      this.onSearch(1,newVal); //获取家园列表
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      years: (state) => state.app.years,
      pagation: (state) => state.indemnityCenter.pagation, //分页信息
      claimList: (state) => state.indemnityCenter.claimList, //列表
      cityList: (state) => state.app.cityList, //市列表
      districtList: (state) => state.app.districtList, //区县列表
    }),
    action() {
      //附件上传 url
      return process.env.NODE_ENV === "production"
        ? window.g.IMPORT_URL_CLAIM
        : "/api/claim/importClaimData";
    },
  },
  created() {
    if (this.userInfo.cityCode !== 'C000') {
       this.city = this.userInfo.cityCode;
       this.cityFlag = true;
    }
    if (this.userInfo.districtCode !== 'D0000') {
       this.district = this.userInfo.districtCode;
       this.districtFlag = true;
    }
    this.getCityListAction(); //获取市列表
    this.onSearch(1,this.years); //获取家园列表
  },
  methods: {
    ...mapActions([
      "getClaimListAction",
      "setPagationAction",
      "getCityListAction",
      "getDistrictListAction",
    ]),
    onImportSuccess(e) {
      //数据导入成功回调
      if (e.result === true) {
        this.onCurrentPage(1);
      } else {
        this.$message({
          type: "error",
          message: e.message,
        });
      }
    },
    onDetail(row) {
      //点击detail 按钮 回调
      this.$router.push({
        path: "/indemnity_center/detail",
        query: { id: row.claimId },
      });
    },
    onCurrentPage(currentPage) {
      /*//分页回调
      let params = {
        currentPage: currentPage,
        pageSize: 10,
      };
      this.getClaimListAction(params);*/
      this.currentPage = currentPage;
      this.onSearch(currentPage,this.years);
    },
    onSearch(currentPage,year) {
      //search 回调
      let params = {
        currentPage: currentPage ? currentPage : 1,
        pageSize: 10,
        dangerPeopleName: this.name,
        cityCode: this.city === "all" ? "" : this.city,
        districtCode: this.district === "all" ? "" : this.district,
      };
      params.year = year;
      this.getClaimListAction(params);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .accident-reason {
  /deep/ .cell-slot {
    .df;
    flex-wrap: wrap;
    span {
      padding: 0 6px;
    }
  }
}
.index-c-w {
  .df;
  .dfc;
}
.table-box,
.filter-box {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
}
.filter-box {
  height: 72px;
  margin-bottom: 10px;
  .df;
  .dfac;
  .filter-citem {
    .df;
    .dfac;
    color: #305baa;
    height: 32px;
    & > span {
      text-align: right;
    }
    .cur-btn {
      margin-left: 50px;
    }
    .label-span1 {
      width: 60px;
    }
    .label-span2 {
      width: 100px;
    }
    .label-span3 {
      width: 120px;
    }
    /deep/ .ar {
      width: 10px;
      height: 10px;
    }
    /deep/ .box-w {
      height: 100%;
      width: 152px;
    }
  }
}

.table-box {
  padding: 20px;
  color: #fff;
  overflow: hidden;
  flex: 1;
}
// .customer-table {
//   &::before {
//     display: none;
//   }
// }
/deep/ .el-icon-arrow-up:before {
  content: "\e6e1";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
/deep/ .popper__arrow,
/deep/ .el-select-dropdown__empty {
  display: none !important;
}
// 滚动条的宽度
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
  position: absolute;
}
// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(28, 42, 117, 0.2);
  border-radius: 3px;
  position: absolute;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}
</style>
